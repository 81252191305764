<template>
  <div>
    <div class="row">
      <div class="col-2">
        <LocaleSwitcher />
        <MainMenu />
      </div>
      <div class="col">
        <div class="container" v-if="disponible">
          <h1 class="text-center">{{ $t("Copla.Transcription.Title") }}</h1>
          <div class="border rounded secciones mt-4" id="transcription-box">
            <span v-html="transcription"></span>
          </div>
          <div class="row mt-3">
            <div class="col">
              <div class="text-center">
                <button
                  type="button"
                  id="comenzar"
                  name="comenzar"
                  class="btn btn-success"
                  v-on:click="connect"
                >
                  {{ $t("Copla.Transcription.Button-On") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="container" v-if="!disponible">
          <p>
            No puede utilizar las características de Webspeech API en este
            navegador. Utilice Chrome.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "../components/LocaleSwitcher";
import MainMenu from "../components/MainMenu";
export default {
  name: "transcriptionWebApì",
  components: {
    LocaleSwitcher,
    MainMenu
  },
  data() {
    return {
      transcription: "",
      todas: [],
      disponible: true
    };
  },
  mounted() {},
  methods: {
    connect: function() {
      var self = this;
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      var recognition = new SpeechRecognition();
      recognition.continuous = true;
      recognition.lang = "es-ES";
      recognition.interimResults = false;
      recognition.start();
      recognition.onstart = function() {
        console.log("Empieza...");
      };
      recognition.onresult = function(event) {
        var resultados = event.results;
        //var confidence = 0;
        var salida = "";
        for (var i = 0; i < resultados.length; i++) {
          if (resultados[i].isFinal) {
            console.log(resultados[i][0].transcript);
            console.log(resultados);
            salida = " " + resultados[i][0].transcript;
          } /*else {
            if (resultados[i][0].confidence > confidence) {
              salida = resultados[i][0].transcript;
            }
          }*/
        }
        self.transcription += salida;
      };
      recognition.onend = function() {
        console.log("On end");
        self.connect();
      };
      recognition.onerror = function(event) {
        console.log("On error");
        console.log(event);
      };
    },
    reiniciar: function() {}
  }
};
</script>

<style scoped>
#transcription-box {
  min-height: 100px;
}
</style>
